import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PdpPageModel from "./ProductDetailsPage.pageModel";
import { PublicInterface } from "../../interface/common";
import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const ProductDetailDisplayView = ({
  productName,
  productDescription,
  helmet,
  isFetching,
  showLoginForPrice,
  additionalInformation,
  productDetailGallery,
  crumbs,
  dynamicContentElements,
  productPrice,
  productDetailHeading,
  productBundle,
  favoritesButton,
  skuOptions,
  skuSelector,
  productConfiguratorSelector,
  productForm,
  productAttributes,
}: PublicInterface<PdpPageModel>) => {
  const {
    ProductModule,
    CommonModule: { DynamicComponent, Spinner, BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();

  const { eventHandlerForWSIWYG } = useUtilities();

  if (isFetching) {
    return (
      <div className="container mt-5">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="bg-light breadcrumb-container p-4">
        <BreadCrumb crumbs={crumbs} />
      </div>
      {helmet && (
        <Helmet>
          {helmet.title && <title>{helmet.title}</title>}
          {helmet.description && <meta name="description" content={helmet.description} />}
          {helmet.keywords && <meta name="keywords" content={helmet.keywords} />}
          {helmet.productSchema && <script type="application/ld+json">{JSON.stringify(helmet.productSchema)}</script>}
        </Helmet>
      )}
      <div className="container mt-5">
        {favoritesButton && ( //changes-favourites display control from config
          <div className="d-flex justify-content-end">
            <ProductModule.CoreComponents.HeartButton className="btn-wishlist mr-0 flex-end" {...favoritesButton} />
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-lg-5 mb-4 mb-md-0">
            {productDetailGallery && <ProductModule.CoreComponents.ProductDetailGallery {...productDetailGallery} />}
            {/*changes-product description moved below the image slider */}
            {productDescription && (
              <div
                className="p-5"
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: productDescription,
                }}
              />
            )}

            {additionalInformation && (
              <ProductModule.CoreControl.ProductAdditionalInformation {...additionalInformation} />
            )}
          </div>
          <div className="col-sm-12 col-lg-6 offset-lg-1">
            <h1 className="fw-bold mb-3 h3">{productName}</h1>
            {productDetailHeading && <ProductModule.CoreComponents.ProductDetailHeading {...productDetailHeading} />}
            {productPrice && (
              <div className="my-4 fw-bold">
                <ProductModule.CoreComponents.ProductPrice {...productPrice} />
              </div>
            )}
            {skuOptions && <ProductModule.CoreComponents.SkuOptions {...skuOptions} />}
            {skuSelector && <ProductModule.CoreComponents.SkuSelector {...skuSelector} />}
            {productConfiguratorSelector && (
              <div className="mb-4">
                <ProductModule.CoreControl.ProductConfiguratorSelector {...productConfiguratorSelector} />
              </div>
            )}

            {productForm && (
              <div className="d-flex align-items-center">
                <ProductModule.CoreComponents.ProductForm {...productForm} />
              </div>
            )}

            {showLoginForPrice && ( //changes - config validation and alerts for showing trade price.
              <div className="alert alert-danger pdp-login-alert" role="alert">
                {t("frontend.loginforprice")}
                <br />
                <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
              </div>
            )}

            {productAttributes && (
              <div className="row mb-4">
                <div className="col-md-12">
                  <ProductModule.CoreComponents.ProductAttributes {...productAttributes} />
                </div>
              </div>
            )}

            {dynamicContentElements?.map((rowEl: any, rowIdx: number) => <DynamicComponent el={rowEl} key={rowIdx} />)}
          </div>
        </div>
        {productBundle && <ProductModule.CoreComponents.ProductBundle {...productBundle} />}
      </div>
    </>
  );
};

export default ProductDetailDisplayView;
