import { useState } from "react";
import { isExternalURL } from "../../utils";
import { useElementContext } from "../../contexts";
import { getSdkURL } from "../../services";

const ListingBanner = ({
  heading = "",
  crumbs = undefined,
  images = [],
  description = "",
  subHeading = "",
  type = "brand",
  showCrumbs = true,
  isCollapsible = true,
  bannerLink = <></>,
}) => {
  const {
    CommonModule: { BreadCrumb },
  } = useElementContext();
  const [imageMissing, setImageMissing] = useState(false);
  const host = getSdkURL();
  const image = !imageMissing && images?.at(0);

  return (
    <div className="container-fluid d-flex flex-column justify-content-center p-0">
      <div className="row">
        {image && (
          <div
            className={`col-xs-12 col-sm-3 col-md-3 col-lg-2 text-center banner-img collapse multi-collapse ${
              isCollapsible ? "" : "show"
            }`}
            id="collapseimage"
          >
            <img
              src={`${isExternalURL(image) ? "" : host}${image}`}
              alt={heading}
              style={{ maxHeight: "125px", border: "1px solid #dee2e6", background: "#fff" }}
              type={type}
              onError={() => setImageMissing(true)}
            />
          </div>
        )}
        <div
          className={`flex-grow-1 col-xs-12 col-sm-7 col-md-6 col-lg-8 d-flex flex-column justify-content-center ${
            isCollapsible ? "" : "flex-grow-1"
          }`}
        >
          <div className="row text-left">
            {bannerLink}

            <h1 className="display-6 align-content-start m-0 pt-0">{heading}</h1>
            {!!subHeading && <h3 className="h5 text-secondary m-0">{subHeading}</h3>}
          </div>

          {description.trim() && (
            <div
              className={`row text-left banner-desc collapse multi-collapse ${isCollapsible ? "" : "show"}`}
              id="collapsedesc"
            >
              <span className="mb-0 mt-2" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </div>

        {isCollapsible && (description.trim() || image) && (
          <div className="d-flex mt-2 mt-md-3 ms-3 ms-sm-0 form-check form-switch col-lg-4 col-md-5 col-sm-5 justify-content-end banner-switch-div">
            <input
              type="checkbox"
              className="form-check-input listing-banner-switch"
              role="switch"
              id="flexSwitchCheckDefault"
              aria-expanded="false"
              data-bs-toggle="collapse"
              data-bs-target=".multi-collapse"
              aria-controls="collapseimage collapsedesc"
            />
          </div>
        )}
      </div>

      {showCrumbs && crumbs && (
        <div className="row pt-2">
          <div className="col-12 text-center">
            <BreadCrumb crumbs={crumbs} />
          </div>
        </div>
      )}
    </div>
  );
};

export { ListingBanner };
